<template>
  <div class="explore-course-card-component">
    <b-row class="justify-content-center justify-content-md-start">
      <div
        class="add-course-card mb-2"
        @click="$bvModal.show('bv-modal-add-course')"
      >
        <div class="content-container">
          <div class="add-circle">
            <img :src="plusIcon" alt="" />
          </div>
          <h3>{{ $t("g.add-course") }}</h3>
        </div>
      </div>
    </b-row>
    <b-row
      v-if="coursesData.length"
      class="justify-content-center justify-content-md-start"
    >
      <b-col
        v-for="course in coursesData"
        :key="course.id"
        cols="auto"
        style="height: 100%"
      >
        <b-card
          :title="course.name"
          @click="openCourse(course.id)"
          :img-src="
            course.image && course.image.length
              ? course.image[0].path
              : courseImagePlaceholder
          "
          :img-alt="course.name"
          img-top
          tag="article"
          class="mb-2 cursor-pointer"
          body-class="d-flex flex-column justify-content-between"
        >
          <b-card-text
            v-if="course.edu_years && course.edu_years.length"
            class="edy-text"
          >
            {{ `${$t("g.Grade")} .${course.edu_years[0].name}` }}
          </b-card-text>
          <b-card-text v-else class="edy-text">
            {{ $t("type_summer") }}
          </b-card-text>
          <b-card-text id="text">
            {{
              course.description
                ? course.description.substr(0, 60)
                : "No description"
            }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!-- Add course modal -->
    <AddCourseModal @createCourse="createCourse" />
    <!-- Add course modal -->
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import { BRow, BCol, BLink, BCard, BButton, BCardText } from "bootstrap-vue";
import AddCourseModal from "../AddCourseModal";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BButton,
    BCardText,
    AddCourseModal,
    OverlayComponent,
  },
  props: ["coursesData", "callData"],
  data() {
    return {
      is_loading: false,
      plusIcon: require("@/assets/dev/plus.svg"),
      courseImagePlaceholder: require("@/assets/dev/courseImagePlaceholder.png"),
    };
  },
  methods: {
    openCourse(courseId) {
      this.$router.push({
        name: "ShowCourse",
        params: {
          id: courseId,
        },
      });
    },
    createCourse() {
      this.$emit("createCourse");
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
