<template>
  <validation-observer ref="reportForm">
    <b-form>
      <b-modal id="bv-modal-add-course" centered hide-footer>
        <!-- Start Modal Header -->
        <template #modal-header>
          <div
            class="mb-2 d-flex justify-content-between w-100 align-items-center"
          >
            <b-card-text class="mb-0">
              {{ $t("g.add-course") }}
            </b-card-text>
            <img
              :src="exitIcon"
              alt="Hide add course"
              @click="$bvModal.hide('bv-modal-add-course')"
            />
          </div>
          <span class="sub-title">
            {{ $t("g.please_fill_in_the_required_data") }}
          </span>
        </template>
        <!-- End Modal Header -->

        <!-- Start Course Name Input -->
        <b-form-group label-for="name" class="mb-2">
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              :placeholder="$t('g.course_name')"
              v-model="courseName"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End Course Name Input -->

        <!-- Start Course Type Select -->
        <div class="course-type-container">
          <b-form-group label-for="course type" class="mb-2">
            <validation-provider
              #default="{ errors }"
              name="course type"
              rules="required"
            >
              <b-form-select :options="typeOptions" v-model="selectedType" />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
              <svg
                class="select-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15.8346 7.5L10.0013 13.3333L4.16797 7.5"
                  stroke="#5E5E5E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </validation-provider>
          </b-form-group>
        </div>
        <!-- End Course Type Select -->

        <!-- Start Edy Year Select -->
        <div v-if="selectedType === 0" class="edy-year-container">
          <b-form-group label-for="educational year" class="mb-2">
            <validation-provider
              #default="{ errors }"
              name="educational year"
              rules="required"
            >
              <b-form-select
                :options="edyYearOptions"
                v-model="selectedEdyYear"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
              <svg
                class="select-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15.8346 7.5L10.0013 13.3333L4.16797 7.5"
                  stroke="#5E5E5E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </validation-provider>
          </b-form-group>
        </div>
        <!-- End Edy Year Select -->

        <!-- Start Date Select -->
        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group label-for="start date">
              <validation-provider
                #default="{ errors }"
                name="start date"
                rules="required"
              >
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :placeholder="$t('g.start_date')"
                  v-model="startDate"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label-for="end date">
              <validation-provider
                #default="{ errors }"
                name="end date"
                rules="required"
              >
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :placeholder="$t('g.end_date')"
                  v-model="endDate"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="dateError" cols="12" class="date-error">
            <p>{{ dateError }}</p>
          </b-col>
        </b-row>
        <!-- End Date Select -->

        <!-- Start description textarea -->
        <b-form-group label-for="description">
          <validation-provider
            #default="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-textarea
              :placeholder="$t('g.description')"
              v-model="description"
              rows="5"
              max-rows="10"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End description textarea -->

        <!-- Start Image Input -->
        <b-form-group label-for="course image" class="mb-4">
          <validation-provider
            #default="{ errors }"
            name="course image"
            rules="required"
          >
            <div class="image-input-area">
              <b-form-file
                class="image-input"
                ref="courseImage"
                @change="handleFileChange"
                accept="image/*"
                v-model="selectedimage"
              />
              <div class="image-icon">
                <img :src="imageInputIcon" alt="Add course image" />
                <div class="icon-circle">
                  <img :src="plusIcon" alt="plus icon" />
                </div>
              </div>
              <p class="image-text mb-0">{{ $t("g.upload_photo") }}</p>
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
          <div
            class="mt-1 d-flex w-100 align-items-center justify-content-between"
            v-if="selectedimage"
          >
            <div class="d-flex">
              <img
                class="mr-1"
                :src="imageIcon"
                width="22"
                height="22"
                alt=""
              />
              <p class="m-0">{{ selectedimage.name }}</p>
            </div>
            <img @click="editImage" :src="editIcon" alt="" />
          </div>
        </b-form-group>
        <!-- End Image Input -->

        <!-- Start Form Actions Buttons -->
        <b-row align-h="end" class="report-footer m-0 mt-5">
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('bv-modal-add-course')"
            >{{ $t("g.cancel") }}
          </b-button>
          <b-button
            :disabled="is_loading"
            variant="primary"
            type="submit"
            @click.prevent="validation"
            >{{ $t("g.send_request") }}
          </b-button>
        </b-row>
        <!-- Start Form Actions Buttons -->

        <!-- Overlay component -->
        <overlay-component :isLoading="is_loading" />
        <!-- Overlay component -->
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  BCol,
  BForm,
  BFormSelect,
  BFormTextarea,
  BRow,
  BFormFile,
  BFormGroup,
  BCardText,
  BFormInput,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormFile,
    OverlayComponent,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
  },
  data() {
    return {
      required: required,
      is_loading: true,
      dateError: "",
      courseName: null,
      selectedType: null,
      selectedEdyYear: null,
      description: null,
      startDate: null,
      endDate: null,
      selectedimage: null,
      typeOptions: [
        { value: null, text: this.$t("g.course_type"), disabled: true },
        { value: 0, text: this.$t("g.academic_course") },
        { value: 1, text: this.$t("g.summer_course") },
      ],
      edyYearOptions: [
        { value: null, text: this.$t("g.educational_year"), disabled: true },
      ],
      exitIcon: require("@/assets/dev/exit_icon.svg"),
      plusIcon: require("@/assets/dev/image_input_icon2.svg"),
      imageInputIcon: require("@/assets/dev/image_input_icon.svg"),
      editIcon: require("@/assets/images/icons/pencil.svg"),
      imageIcon: require("@/assets/images/icons/photo.png"),
    };
  },
  created() {
    this.getEduYear();
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getEduYear() {
      try {
        this.is_loading = true;
        const res = await this.$http.get("/teacher/edu-years");
        for (const eduYear of res.data.data) {
          this.edyYearOptions.push({
            value: eduYear.id,
            text: eduYear.name,
          });
        }
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    async createCourse() {
      try {
        this.is_loading = true;
        const formData = new FormData();
        formData.append("name", this.courseName);
        formData.append("is_summer", this.selectedType);
        this.selectedType === 0 &&
          formData.append("edu_year", this.selectedEdyYear);
        formData.append("start_date", this.startDate);
        formData.append("end_date", this.endDate);
        formData.append("description", this.description);
        formData.append("image", this.selectedimage);
        formData.append("color", "white");
        formData.append("is_enrollement_open", 1);
        await this.$http.post("/teacher/courses", formData);
        this.makeToast(
          "success",
          this.$t("g.send.successBody"),
          this.$t("g.send.successTitle")
        );
        this.$bvModal.hide("bv-modal-add-course");
        this.$emit("createCourse");
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    editImage() {
      this.selectedimage = null;
      this.$refs.courseImage.$el.querySelector('input[type="file"]').click();
    },
    handleFileChange(event) {
      this.selectedimage = event.target.files[0];
    },
    validation() {
      const dateState = this.compareShortDates();
      this.$refs.reportForm
        .validate()
        .then((success) => success && dateState && this.createCourse());
    },
    // compare start and end date
    compareShortDates() {
      if (this.startDate && this.endDate) {
        const startDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        const now = new Date();

        if (startDate < now) {
          this.dateError = this.$t(
            "g.alert.the_start_date_must_be_in_the_future"
          );
          return 0;
        } else if (startDate >= endDate) {
          this.dateError = this.$t(
            "g.alert.the_start_date_must_be_before_end_date"
          );
          return 0;
        } else if (startDate < endDate) {
          this.dateError = "";
          return 1;
        }
      }
    },
  },
  watch: {
    startDate() {
      this.dateError = false;
    },
    endDate() {
      this.dateError = false;
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
