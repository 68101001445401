<template>
  <loading v-if="is_loading" />
  <div v-else>
    <course-card-component
      :coursesData="courses"
      @createCourse="createCourse"
    />
    <div class="py-3" v-if="courses.length">
      <b-pagination
        size="lg"
        limit="4"
        align="center"
        :total-rows="rows"
        v-model="currentPage"
      />
    </div>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import loading from "@/components/loading/loading.vue";
import { BPagination } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import CourseCardComponent from "@/components/classrooms/course/CourseCardComponent/index.vue";

export default {
  components: {
    loading,
    BPagination,
    OverlayComponent,
    CourseCardComponent,
  },
  data() {
    return {
      courses: [],
      is_loading: true,
      overlayLoading: false,
      rows: null,
      currentPage: 1,
    };
  },
  beforeMount() {
    this.getAllcourses();
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getAllcourses(innerLoading = false) {
      try {
        innerLoading && (this.overlayLoading = true);
        const res = await this.$http.get(
          `/teacher/courses?my_courses=true&page=${this.currentPage}`,
          {
            headers: {
              "X-Page-Size": 20,
            },
          }
        );
        this.courses = res.data.data;
        this.rows = response.data.meta.total;
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        innerLoading
          ? (this.overlayLoading = false)
          : (this.is_loading = false);
      }
    },
    createCourse() {
      this.getAllcourses();
    },
  },
  watch: {
    currentPage() {
      this.getAllcourses(true);
    },
  },
};
</script>

<style lang="scss"></style>
